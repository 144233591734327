<template>
  <div class="container">
    <TheHeader />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <ResetRequestForm />
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import ResetRequestForm from '@/components/Reset/ResetRequestForm'

export default {
  components: {
    TheHeader,
    ResetRequestForm,
  },
}
</script>
