<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <div class="card">
      <div class="card-header">
        Request Password Reset
      </div>
      <div class="card-body">
        <p>Enter your email address with the publication.</p>
        <a-form-item
          name="email"
          :rules="[
            { required: true, message: 'Email address is required.' },
            { type: 'email', message: 'Email address is invalid.' },
          ]"
        >
          <a-input
            v-model:value="values.email"
            name="email"
            placeholder="name@company.com"
          />
        </a-form-item>
      </div>
      <div class="card-footer">
        <a-button type="primary" html-type="submit" :loading="loading">
          Request Reset
        </a-button>
        <a-button type="link" @click="$router.push({ name: 'login' })">
          Back to login
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      values: {
        email: null,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        await this.$http.post('/me/password', values)

        this.$message.success('You will receive instructions to reset your password if this email address is registered.')
        this.$router.push({ name: 'login' })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
